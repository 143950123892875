import React from 'react';
import { useAuth } from '../useAuth'
import { Button, Checkbox, Form, Input } from 'antd';
import { useNavigate } from "react-router-dom";


export const Login = () => {

  const auth = useAuth();
  let navigate = useNavigate();

  console.log(auth)
  const onFinish = (values) => {
    // console.log(useAuth())
    console.log('Success:', values.researchCode);

    return auth.signin(values.researchCode, ()=>{navigate(-1)})
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
  <Form
    name="basic"
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 16,
    }}
    style={{
      maxWidth: 600,
    }}
    initialValues={{
      remember: true,
    }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >
    <Form.Item
      label="Research Code1"
      name="researchCode"
      rules={[
        {
          required: true,
          message: 'Please input your research code!',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>
);}