// ProjectStormer.js

import React, { useState, useEffect, useRef } from 'react';
import NavBarIdea from './NavBar/NavBarIdea';
import { ClipLoader } from 'react-spinners';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import CustomIdeaForm from './Form/CustomIdeaForm';
import {server, logData} from '../api.js'
import { useAuth } from '../useAuth'
import '../index.css';

function ProjectStormer() {
  const [theme, setTheme] = useState('');
  const [module, setModule] = useState('');
  const [prompt, setPrompt] = useState('');
  const [generatedIdeas, setGeneratedIdeas] = useState('');
  const [loading, setLoading] = useState(false);

  const [editIndex, setEditIndex] = useState(-1);
  const [editedProject, setEditedProject] = useState('');

  const [customIdeas, setCustomIdeas] = useState([]);

  const cardRefs = useRef([]);
  const navigate = useNavigate();

  // const [fakeIdeas, setFakeIdeas] = useState('#### Project name: Taste of Vietnam\n**Backdrop:** Chicago lakefront with skyline in the background\n**Three sprites:**\n- Sprite 1: A Vietnamese flag (object)\n- Sprite 2: Bánh mì truck (object)\n- Sprite 3: Pho noodle truck (object)\n**Sprite functionality:**\n- The Vietnamese flag waves in place\n- The Bánh mì truck and Pho noodle truck drive side by side across the screen from left to right\n\n#### Project name: Street Food Race\n**Backdrop:** Bustling street scene at the Chicago lakefront\n**Three sprites:**\n- Sprite 1: Street food cart sign (object)\n- Sprite 2: Spring roll cart (object)\n- Sprite 3: Coffee cart (object)\n**Sprite functionality:**\n- The street food cart sign swings gently in the breeze\n- The Spring roll cart and Coffee cart move swiftly across the screen from left to right\n\n#### Project name: Lakeside Food Fest\n**Backdrop:** Lakeside view with food stalls\n**Three sprites:**\n- Sprite 1: Festival lanterns (object)\n- Sprite 2: Baozi (steamed buns) food truck (object)\n- Sprite 3: Tapioca pudding stand (object)\n**Sprite functionality:**\n- Festival lanterns flicker and sway in place\n- The Baozi food truck and Tapioca pudding stand move across the screen from left to right\n\n#### Project name: Evening Eats\n**Backdrop:** Sunset at Lake Michigan with distant city lights\n**Three sprites:**\n- Sprite 1: Moon lantern (object)\n- Sprite 2: Noodle soup cart (object)\n- Sprite 3: Sticky rice vendor (object)\n**Sprite functionality:**\n- Moon lantern glows and bobs slightly in the air\n- The noodle soup cart and sticky rice vendor\nprogress side by side from left to right across the screen');




  const auth = useAuth();
  const rc = auth.getResearchCode();

  useEffect(() => {
    logData(rc, 'PROJECTSTORMER_LOADED',{})
    // Retrieve the theme from local storage
    const savedTheme = localStorage.getItem('projectTheme');
    if (savedTheme) {
      logData(rc, 'PROJECTSTORMER_SAVED_THEME_FOUND',{savedTheme:savedTheme})
      setTheme(savedTheme);
    }

    // Retrieve the module from local storage
    const savedModule = localStorage.getItem('selectedModule');
    if (savedModule) {
      logData(rc, 'PROJECTSTORMER_SAVED_MODULE_FOUND',{savedModule:savedModule})
      setModule(savedModule);
    }

    // Retrieve the prompt from local storage
    const savedPrompt = localStorage.getItem('modulePrompt');
    if (savedPrompt) {
      const updatedPrompt = savedPrompt.replace('<PLACEHOLDER THEME>', savedTheme);
      logData(rc, 'PROJECTSTORMER_SAVED_PROMPT_FOUND',{updatedPrompt:updatedPrompt})
      setPrompt(updatedPrompt);
      generateIdeas(updatedPrompt);  // Generate ideas using the updated prompt
    }
  }, []);

  const generateIdeas = async (prompt) => {
    setLoading(true);
    try {
      const response = await fetch(`${server}/generate_ideas`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ prompt })
      });

      const data = await response.json();
      console.log(data.output);

      if (data.output) {

      logData(rc, 'PROJECTSTORMER_IDEAS_GENERATED',{ideasGenerated:data.output})
        setGeneratedIdeas(data.output);
      } else {
        console.error("Failed to generate ideas:", data.error);
      }
    } catch (error) {
      console.error('Error generating ideas:', error);
    } finally {
      setLoading(false);
    }
  };

  const parseGeneratedIdeas = (ideas) => {
    const projectPattern = /#### Project name: [\s\S]*?(?=#### Project name:|$)/g;
    return ideas.match(projectPattern) || [];
  };

  const preprocessProjectIdea = (idea) => {
    return idea
      .replace(/(\n)(\*\*Three sprites:\*\*)/g, '\n\n$2')
      .replace(/(\n)(\*\*Sprite functionality:\*\*)/g, '\n\n$2');
  };

  const projects = parseGeneratedIdeas(generatedIdeas).map(preprocessProjectIdea);
  // const projects = parseGeneratedIdeas(fakeIdeas).map(preprocessProjectIdea);

  useEffect(() => {
    const setCardHeights = () => {
      const cardHeights = cardRefs.current.map(ref => ref.clientHeight);
      const maxHeight = Math.max(...cardHeights);
      cardRefs.current.forEach(ref => {
        ref.style.height = `${maxHeight}px`;
      });
    };

    if (!loading && projects.length > 0) {
      setCardHeights();
    }
  }, [loading, projects]);

  const handleEditClick = (index, projectContent) => {
    logData(rc, 'PROJECTSTORMER_PROJECT_EDIT', {project:projects[index]})
    setEditIndex(index);
    setEditedProject(projectContent);
  };

  const handleSaveClick = () => {
    const updatedProjects = [...projects];
    updatedProjects[editIndex] = editedProject;
    logData(rc, 'PROJECTSTORMER_PROJECT_EDIT_SAVE', {original:projects[editIndex], new:editedProject})
    setGeneratedIdeas(updatedProjects.join('\n\n'));
    setEditIndex(-1);
  };

  const handleDiscardClick = () => {
    logData(rc, 'PROJECTSTORMER_PROJECT_EDIT_DISCARD', {project:projects[editIndex]})
    setEditedProject(projects[editIndex]);
    setEditIndex(-1);
  };

  const handleSelectClick = (index) => {
    logData(rc, 'PROJECTSTORMER_PROJECT_SELECTED', {project:projects[index]})
    navigate('/picstormer', { state: { project: projects[index], theme, module } });
  };

  const handleCustomIdeaSubmit = (idea) => {
    setCustomIdeas([...customIdeas, idea]);
    logData(rc, 'PROJECTSTORMER_CUSTOM_PROJECT', {project:idea})
    navigate('/picstormer', { state: { project: idea, theme, module } });
  };

  return (
    <div className="row flex-nowrap">
      {/* Nav Bar */}
      <NavBarIdea />

      {/* Main Content */}
      <div className="col py-3">
        <h1 className="text-center fw-bold">HARMONIZING SCRATCH ENCORE</h1>
        <h2 className="text-center">Step 3 of 5: Choose project idea</h2>
        <hr className="mx-auto" width="75%"/>

        <div className="container">
          <div className="container mb-3 w-75">
            <div className="card bg-light">
              <div className="card-body">
                <h5 className="card-title">Module: {module}</h5>
                <h6 className="card-subtitle mb-1 text-muted">Theme: {theme}</h6>
              </div>
            </div>
          </div>

          {loading ? (
            <div className="text-center">
              <ClipLoader size={50} color={"#123abc"} loading={loading} />
              <p>Generating project ideas...</p>
            </div>
          ) : (
            <div className="container">
              {projects.length > 0 ? (
                <div className="row">
                  {projects.map((project, index) => (
                    <div className="col-md-6 mb-3" key={index}>
                      <div className="card" id="project-idea" ref={el => cardRefs.current[index] = el}>
                        <div className="card-body" id="project-idea">
                          <div className="card-content" id="project-idea">
                            {editIndex === index ? (
                              <textarea
                                className="form-control project-edit"
                                value={editedProject}
                                onChange={(e) => setEditedProject(e.target.value)}
                              />
                            ) : (
                              <ReactMarkdown>{project}</ReactMarkdown>
                            )}
                          </div>
                          <div className="d-flex justify-content-end mt-auto">
                            {editIndex === index ? (
                              <>
                                <button className="btn btn-secondary me-2" onClick={handleDiscardClick}>Discard</button>
                                <button className="btn btn-primary" onClick={handleSaveClick}>Save</button>
                              </>
                            ) : (
                              <>
                                <button className="btn btn-secondary me-2" onClick={() => handleEditClick(index, project)}>Edit</button>
                                <button className="btn btn-primary" onClick={() => handleSelectClick(index)}>Use this idea</button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>No ideas generated.</div>
              )}
              <CustomIdeaForm onSubmit={handleCustomIdeaSubmit} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProjectStormer;