import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Row, Spin,Button } from 'antd';
import NavBarPic from './NavBar/NavBarPic';
import ReactMarkdown from 'react-markdown';
import {generateSuggestions, logData} from '../api.js'
import { useAuth } from '../useAuth'
import SuggestionsOverview from './SuggestionsOverview';
import GenerationOverview from './GenerationOverview';
import axios from 'axios';

function PicStormer() {
  const location = useLocation();
  const { project, theme, module } = location.state || {};

  const [projectIdea, setProjectIdea] = useState(project || '');
  const [projectTheme, setProjectTheme] = useState(theme || '');
  const [projectModule, setProjectModule] = useState(module || '');
  const [generateMode, setGenerateMode] = useState(false);
  const [generateSpriteType, setGenerateSpriteType] = useState('');
  const [generateSpriteName, setGenerateSpriteName] = useState('');
  const [spriteNames, setSpriteName] = useState([]);
  const [spriteTypes, setSpriteTypes] = useState({});
  const [sprites, setSprites] = useState([]);
  const [selectedSprites, setSelectedSprites] = useState({});
  const [recievedSuggestions, setRecievedSuggestions] = useState(false);



  const auth = useAuth();
  const rc = auth.getResearchCode();

  // TODO: Minh I am using dummy data for now but feel free to replace with the actual spriteNames
  // const dummySpriteNames = ['Hula Dancer', 'Pineaple Float', 'Ukulele Float',]

  // TODO: David: replace cat image link with an api call to server to get the a real suggestions

  // TODO: David, I added this function to extract backdrop and sprite information from project idea
  // `backdrop`` is a string, `sprites` is a 2-d array storing sprite names and their corresponding types
  const parseProjectIdea = (idea) => {
    const projectPattern = /#### Project name: [\s\S]*?(?=#### Project name:|$)/g;
    const projects = idea.match(projectPattern) || [];
    const sprites = [];
    let backdrop = '';

    projects.forEach(project => {
      const spritePattern = /- Sprite \d+: (.+?) \((.+?)\)/g;
      const matches = Array.from(project.matchAll(spritePattern));

      // Extract backdrop name
      const backdropPattern = /\*\*Backdrop:\*\* (.+)/;
      const backdropMatch = project.match(backdropPattern);
      if (backdropMatch) {
        backdrop = backdropMatch[1];
      }

      // Extract sprite names and types
      const spritesInProject = [];
      matches.forEach(match => {
        const spriteName = match[1];
        const spriteType = match[2];
        spritesInProject.push({ name: spriteName, type: spriteType });
      });

      sprites.push(spritesInProject);
    });

    console.log('Backdrop:', backdrop);
    console.log('Sprites:', sprites);
    return { backdrop, sprites };
  };



  const handleDownload = async () => {
    logData(rc,'PICSTORMER_DOWNLOAD', {selectedSprites:selectedSprites, sprites:sprites})
    try {
      const response = await axios({
        url: 'http://127.0.0.1:8080/download_project', // URL of your Flask endpoint
        method: 'POST', // Assuming the Flask endpoint is a POST request
        responseType: 'blob', // Important
        data: {
          selectedSprites:selectedSprites,
          sprites:sprites,
          spriteTypes:spriteTypes,
          orderedSprites:spriteNames
          // Add any necessary data if required by the POST request
        }
      });

      // Create a URL for the file and download it
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'project.sb3'); // You can specify the filename here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  const generateCallback = (spriteName) =>{
    setGenerateMode(true)
    setGenerateSpriteName(spriteName)

    // TODO: Minh I am not sure how you are transfering the information regarding human object or animal into picstormer so just
    // replace this with whatever spriteName actually is.
    console.log(spriteTypes[spriteName])
    setGenerateSpriteType(spriteTypes[spriteName])
  }

  const updateGeneratedSelections = (selections) =>{
    const i = spriteNames.indexOf(generateSpriteName)
    const new_sprites = [...sprites[i], ...selections]
    const prevLength = sprites[i].length;

    const copiedArray = [];
  
    // Loop through each sub-array in the original array
    for (let i = 0; i < sprites.length; i++) {
        // Copy each sub-array using slice (or any other method for deep copying arrays)
        const copiedSubArray = sprites[i].slice();
        // Add the copied sub-array to the new array
        copiedArray.push(copiedSubArray);
    }
    // for()
    
    const prevSelections = selectedSprites[generateSpriteName]
    const updatedSelections = prevSelections == null ? [] : [...prevSelections, ...selections.map((v, i) =>i+prevLength)]
    // console.log(`prev selections: ${prevSelections} updatedSelections: ${updatedSelections}`)

    copiedArray[i] = new_sprites;
    setSprites(copiedArray);
    setGenerateMode(false);
    updateSelections(generateSpriteName, updatedSelections);



    // dumm
    // console.log(i)


  }

  const updateSelections = (spriteToUpdate, selections) =>{
    // console.log(`${selections})
    // console.log(spriteToUpdate)
    const newSelections = structuredClone(selectedSprites);
    newSelections[spriteToUpdate] = selections;
    setSelectedSprites(newSelections);
    logData(rc,'PICSTORMER_SELECTION_UPDATED',{'Selections':newSelections, 'Sprites':sprites})
  }

  useEffect(() => {
    logData(rc, 'PICSTORMER_LOADED',{theme:theme,module:module})
    // console.log(parseProjectIdea(projectIdea)['sprites'][0].map((s)=>s.name));
    const spriteBackdrop = parseProjectIdea(projectIdea)
    const tempSpriteData = spriteBackdrop['sprites'][0];

    const backdrop = spriteBackdrop['backdrop']
    tempSpriteData.push({'name':backdrop, 'type':'backdrop'})
    const tempSpriteNames = tempSpriteData.map((s)=>s.name);

    const tempSpriteTypes = tempSpriteData.reduce((acc, current) => {
      acc[current.name] = current.type;
      return acc;
    }, {});
    console.log(tempSpriteData,'=<')
    // tempSpriteData.push()
    // console.log(tempSpriteTypes,'=====')
    // tempSpriteTypes[backdrop] = 'backdrop'
    // tempSpriteNames.push(backdrop)
    console.log(tempSpriteNames,'=====')

    setSpriteTypes(tempSpriteTypes);
    setSpriteName(tempSpriteNames);
    setRecievedSuggestions(false);
    generateSuggestions(tempSpriteData, (r)=>{
        setSprites(r.data.suggestions);
        setRecievedSuggestions(true);
        logData(rc, 'PICSTORMER_SUGGESTIONS_RECIEVED', {spriteData:tempSpriteData, suggestions:r.data.suggestions})
      });


  const cat = "https://en.scratch-wiki.info/w/images/ScratchCat3.0.svg"
  const dummySprites = []
  for(var i = 0; i < tempSpriteNames.length; i++){
    dummySprites.push([cat,cat])
  }
    setSprites(dummySprites);
    // setSprites([]);
    setSelectedSprites({});

    logData('ResearchCodeTest', {'Foo':'Bar'})


    if (theme) setProjectTheme(theme);
    if (module) setProjectModule(module);
  }, [theme, module]);


  const allSpritesSelected = spriteNames.map((n) => selectedSprites[n] == null ? false : selectedSprites[n].length > 0).every(Boolean);
  // console.log(allSpritesSelected)
  return (
    <div className="row flex-nowrap">
      {/* Nav Bar */}
      <NavBarPic />

      {/* Main Content */}
      <div className="col py-3">
        <h1 className="text-center fw-bold">HARMONIZING SCRATCH ENCORE</h1>
        <h2 className="text-center">Step 4 of 5: Choose sprites and backdrop</h2>
        <hr className="mx-auto" width="75%"/>

        <div className="container">
          <div className="container mb-3 w-75">
            <div className="card bg-light">
              <div className="card-body">
                <h5 className="card-title">Module: {projectModule}</h5>
                <h6 className="card-subtitle mb-1 text-muted">Theme: {projectTheme}</h6>
                <ReactMarkdown>{projectIdea}</ReactMarkdown>
              </div>
            </div>
            {generateMode ? <GenerationOverview spriteName={generateSpriteName} spriteType={generateSpriteType} callback={(selections)=>{updateGeneratedSelections(selections)}}/> : 
              <>
                <Row>
                <Spin spinning={!recievedSuggestions}>
                  <SuggestionsOverview spriteNames={spriteNames} spriteSuggestions={sprites}
                    onGenerate={generateCallback} updateSelections={updateSelections} 
                    selected={selectedSprites}/>
                </Spin>
                </Row>
                <br/>
                <Row style={{textAlign:'center'}}>
                  <Col span={24}>
                    {allSpritesSelected ? 
                      <Button type='primary' onClick={handleDownload}>Download Project</Button> 
                      : 
                      <Button type='primary' disabled >Download Project</Button> 
                      }
                  </Col>
                </Row>

              </>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PicStormer;