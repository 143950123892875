import React, { useState, useEffect, useContext, createContext } from "react";
import axios from 'axios'


// export const server = 'https://backend-quantime.link';
export const server = 'http://127.0.0.1:8080';

export const generateSuggestions = (spriteData, callback=(r)=>{console.log(r)}) => {
	axios.post(server+'/suggestions', {spriteData:spriteData}).then((r)=>callback(r))
}

export const generateImage = (prompt,n, callback=(r)=>{console.log(r)}) => {
	axios.post(server+'/genImage', {prompt:prompt, n:n}).then((r)=>callback(r))
}

function getFormattedTimestamp() {
  const now = new Date();

  const pad = (num) => num.toString().padStart(2, '0');

  const month = pad(now.getMonth() + 1); // Months are zero-based
  const day = pad(now.getDate());
  const year = now.getFullYear();

  const hours = pad(now.getHours());
  const minutes = pad(now.getMinutes());
  const seconds = pad(now.getSeconds());

  return `${month}/${day}/${year}-${hours}:${minutes}:${seconds}`;
}


export const logData = (researchCode, event, data, callback=(r)=>{console.log(r)} ) => {

	axios.post(server+'/log', {logData:data, event:event, researchCode:researchCode, userTime:getFormattedTimestamp()}).then((r)=>callback(r))
}