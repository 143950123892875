import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import NavBarModule from './NavBar/NavBarModule';
import {server, logData} from '../api.js'
import { useAuth } from '../useAuth'
function ModuleList() {
  const [prompt, setPrompt] = useState('');
  const navigate = useNavigate();
  const auth = useAuth();
  useEffect(() => {
    const rc = auth.getResearchCode();
    logData(rc, 'MODULE_LIST_LOADED', {});

  }, []);
  const fetchPrompt = async (moduleId) => {
    
    const rc = auth.getResearchCode();
    logData(rc, 'MODULE_SELECTED', {module:moduleId});

    try {
      const response = await fetch(`${server}/get_prompt?module=module${moduleId}`);
      const data = await response.json();
      if (data.prompt) {
        setPrompt(data.prompt); // Save the prompt to the state
        localStorage.setItem('modulePrompt', data.prompt); // Save the prompt to local storage
      } else {
        console.error("Prompt not found for the selected module.");
      }
    } catch (error) {
      console.error('Error fetching the prompt:', error);
    }
  };

  const handleModuleClick = (moduleId, moduleName) => {
    fetchPrompt(moduleId);
    localStorage.setItem('selectedModule', moduleName); // Save the module name to local storage
    navigate(`/themestormer`);  // Navigate to the themestormer route
  };

  return (
    <div className="row flex-nowrap">
      {/* Navbar */}
      <NavBarModule />

      {/* Main Content */}
      <div className="col py-3">
        <h1 className="text-center fw-bold">HARMONIZING SCRATCH ENCORE</h1>
        <h2 className="text-center">Step 1 of 5: Choose module</h2>
        <hr className="mx-auto" width="75%" />

        <div className="container d-grid gap-2">
          <div className="row justify-content-md-center">
            <div className="col-sm-4">
              <Link to="/" onClick={() => handleModuleClick(1, 'Scratch Basics')}>
                <img src={require("../assets/images/thumbnailM1.png")} alt="module 1" className="img-thumbnail" />
              </Link>
            </div>
            <div className="col-sm-4">
              <Link to="/" onClick={() => handleModuleClick(2, 'Events')}>
                <img src={require("../assets/images/thumbnailM2.png")} alt="module 2" className="img-thumbnail" />
              </Link>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-sm-4">
              <Link to="/themestormer" onClick={() => handleModuleClick(3, 'Animation')}>
                <img src={require("../assets/images/thumbnailM3.png")} alt="module 3" className="img-thumbnail" />
              </Link>
            </div>
            <div className="col-sm-4">
              <Link to="/" onClick={() => handleModuleClick(4, 'Conditional Loops')}>
                <img src={require("../assets/images/thumbnailM4.png")} alt="module 4" className="img-thumbnail" />
              </Link>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-sm-4">
              <Link to="/" onClick={() => handleModuleClick(5, 'Decomposition by Sequence')}>
                <img src={require("../assets/images/thumbnailM5.png")} alt="module 5" className="img-thumbnail" />
              </Link>
            </div>
            <div className="col-sm-4">
              <Link to="/" onClick={() => handleModuleClick(6, 'One-Way Synchronization')}>
                <img src={require("../assets/images/thumbnailM6.png")} alt="module 6" className="img-thumbnail" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModuleList;