import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBarTheme from './NavBar/NavBarTheme';
import ThemeForm from './Form/ThemeQuestionForm';
import { ClipLoader } from 'react-spinners';
// import {server} from '../api.js'
import {server, logData} from '../api.js'
import { useAuth } from '../useAuth'

function ThemeStormer() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [suggestedThemes, setSuggestedThemes] = useState([]);
  const [customTheme, setCustomTheme] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false); // New state variable
  const navigate = useNavigate();

  const auth = useAuth();
  const rc = auth.getResearchCode();

  useEffect(() => {
    logData(rc, 'THEMESTORMER_LOADED', {});

  }, []);

  const handleFormSubmit = async (formData) => {
    setIsSubmitting(true);
    setFormSubmitted(true); // Disable the form button once submitted

    try {
      logData(rc, 'TEHMESTORMER_SUBMITTED', {themeData:formData})
      const response = await fetch(`${server}/generate_themes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();
      if (data.themes) {
        logData(rc, 'THEMESTORMER_SUGGESTIONS',{suggestions:data.themes} )
        setSuggestedThemes(data.themes);

      } else {
        console.error("Failed to generate themes:", data.error);
      }
    } catch (error) {
      console.error('Error generating themes:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleThemeSelect = (theme) => {
    logData(rc,'THEMESTORMER_THEME_SELECTED', {selected:theme})
    localStorage.setItem('projectTheme', theme);
    navigate('/projectstormer');
  };

  // const renderInitialOptions = () => (
  //   <div className="text-center mt-4">
  //     <button className="btn btn-primary me-3" onClick={() => setSelectedOption('own')}>
  //       I have my own theme
  //     </button>
  //     <button className="btn btn-secondary" onClick={() => setSelectedOption('helper')}>
  //       I want to use the brainstorming helper
  //     </button>
  //   </div>
  // );

  const renderCustomThemeInput = () => (
    <div className="mt-4">
      <h4 className="text-center">Enter Your Own Theme:</h4>
      <input
        type="text"
        className="form-control"
        value={customTheme}
        onChange={(e) => setCustomTheme(e.target.value)}
        placeholder="e.g., Moon Festival Celebration in Chicago Chinatown, Winter Concert at Ocotillo Elementary School"
      />
      <div className="text-center">
        <button
          className="btn btn-primary mt-2"
          onClick={() => handleThemeSelect(customTheme)}
          disabled={!customTheme.trim()}
        >
          Use My Own Theme
        </button>
      </div>
    </div>
  );

  const renderThemeForm = () => (
    <div>
      <ThemeForm onSubmit={handleFormSubmit} formSubmitted={formSubmitted} />
      {isSubmitting && (
        <div className="text-center mt-4">
          <ClipLoader size={50} color={"#123abc"} loading={true} />
          <p>Generating themes...</p>
        </div>
      )}
      {suggestedThemes.length > 0 && (
        <div className="container mt-4">
          <h4>Choose From Suggested Themes:</h4>
          <ul className="list-group">
            {suggestedThemes.map((theme, index) => (
              <li key={index} className="list-group-item" onClick={() => handleThemeSelect(theme)}>
                {theme}
              </li>
            ))}
          </ul>
          {/*<div className="mt-5">
            {renderCustomThemeInput()}
          </div>*/}
        </div>
      )}
    </div>
  );

  return (
    <div className="row flex-nowrap">
      <NavBarTheme />
      <div className="col py-3">
        <h1 className="text-center fw-bold">HARMONIZING SCRATCH ENCORE</h1>
        <h2 className="text-center">Step 2 of 5: Choose theme</h2>
        <hr className="mx-auto" width="75%" />

        <div className="container w-75">
          {/*{selectedOption === '' && renderInitialOptions()}
          {selectedOption === 'own' && renderCustomThemeInput()}
          {selectedOption === 'helper' && renderThemeForm()}*/}
          {renderCustomThemeInput()}
          {renderThemeForm()}
        </div>
      </div>
    </div>
  );
}

export default ThemeStormer;